import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { RangeAsDateType } from '../models';

const intervalWords = {
  d: 'day',
  w: 'week',
  m: 'month',
  y: 'year',
};

const intervalNames = {
  d: 'daily',
  w: 'weekly',
  m: 'monthly',
  y: 'yearly',
};

export const months = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sept' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

export function getNumDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}

// Example: listYearsTilNow(10), current year is 2024
// Returns [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
export function listYearsTilNow(numYears: number): number[] {
  const currentYear = new Date().getFullYear();
  const years = [] as number[];

  for (let i = 0; i < numYears; i++) {
    years.push(currentYear - i);
  }

  return years;
}

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

// Example: "5 days"
export const fromNow = (date: string | Date | number | undefined) => {
  dayjs.extend(relativeTime);
  return dayjs(date).fromNow(true);
};

export const addDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatDateAsWords = (inputDate: Date) => {
  const date = dayjs(inputDate);
  const month = date.format('MMM');
  const day = date.format('D');
  const year = date.format('YYYY');
  const dayWithSuffix = addDaySuffix(parseInt(day));

  // Example: Sept 5th 2023
  return `${month} ${dayWithSuffix} ${year}`;
};

export const isValidDateFormat = (dateString: string) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/;
  return dateRegex.test(dateString);
};

export const formatDateAsFullLengthWords = (inputDate: string) => {
  const date = dayjs(inputDate);
  const month = date.format('MMMM');
  const day = date.format('D');
  const year = date.format('YYYY');
  const dayWithSuffix = addDaySuffix(parseInt(day));

  // Example: September 5th 2023
  return `${month} ${dayWithSuffix} ${year}`;
};

export const dateFormatter = (
  date: string | Date | number | undefined,
  format = 'MM/DD',
) => {
  if (!date) return null;
  return dayjs.utc(date).format(format);
};

export const dateMonthDayYearFormatter = (
  date: string | Date | number | undefined,
  format = 'MM/DD/YYYY',
) => {
  if (!date) return null;
  return dayjs(date).format(format);
};

export const dateYearMonthDayFormatter = (
  date: string | Date | number | undefined,
  format = 'YYYY/MM/DD',
) => {
  if (!date) return undefined;
  return dayjs(date).format(format);
};

export const dateYearMonthDayWithTimeFormatter = (
  date: string | Date | number | undefined,
  format = 'YYYY/MM/DD HH:mm',
) => {
  if (!date) return undefined;
  return dayjs(date).format(format);
};

export const formDateAsDashedYearMonthDay = (
  date: string | Date | number | undefined,
  format = 'YYYY-MM-DD',
) => {
  if (!date) return undefined;
  return dayjs(date).format(format);
};

export const getTodaysDateFormatted = () => {
  const date = new Date();
  return dateFormatter(date, 'MM/DD/YY');
};

export const getDateThirtyDaysAgo = () => {
  const today = new Date();
  const priorDate = new Date(today);
  priorDate.setDate(today.getDate() - 30);
  return priorDate;
};

export const getFormattedDate = (date?: Date): string => {
  if (!date) return '';
  return dateFormatter(date, 'MM/DD/YY') || '';
};

export const getIntervalName = (input: string): string => {
  const pattern = /(\d+)([wdmy])-([wdmy])/;

  if (!pattern.test(input)) throw new Error(`Invalid range string (${input})`);

  const [, , identifier] = (input.match(pattern) as string[]).slice(1);

  const identifierWord =
    intervalWords[identifier as keyof typeof intervalWords];

  return identifierWord;
};

interface UrlDate {
  date: Date;
  interval: string;
}

export const dateFromUrl = (input: string): UrlDate => {
  const pattern = /([wm])-(\d\d\d\d-\d\d-\d\d)/;

  if (!pattern.test(input))
    return {
      date: dayjs().subtract(1, 'week').toDate(),
      interval: 'week',
    };

  const [intervalId, dateStr] = (input.match(pattern) as string[]).slice(1);

  const interval = intervalWords[intervalId as keyof typeof intervalWords];

  const date = dayjs(dateStr).toDate();

  return {
    date,
    interval,
  };
};

export const expandDateRange = (input: string): RangeAsDateType => {
  const pattern = /(\d+)([wdmy])-([wdmy])/;

  if (!pattern.test(input)) throw new Error(`Invalid range string (${input})`);

  const [amount, identifier, interval] = (
    input.match(pattern) as string[]
  ).slice(1);

  const identifierWord =
    intervalWords[identifier as keyof typeof intervalWords];

  const currentDate = dayjs();
  const startDate = currentDate.subtract(
    Number(amount),
    identifierWord as ManipulateType,
  );
  const endDate = currentDate;

  const intervalName = intervalNames[interval as keyof typeof intervalNames];

  return {
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
    interval: intervalName,
  };
};

export const getDifferenceInDays = (
  start: string | number | Date,
  end: string | number | Date,
) => {
  const startTimestamp = new Date(start).getTime();
  const endTimestamp = new Date(end).getTime();

  if (isNaN(startTimestamp) || isNaN(endTimestamp)) {
    throw new Error('Invalid date inputs');
  }

  return Math.ceil(
    Math.abs(endTimestamp - startTimestamp) / MILLISECONDS_PER_DAY,
  );
};

export const getSelectionContents = (interval: string, selectedDate: Date) => {
  const date = dayjs(selectedDate);

  // Month
  return {
    startDate: date.startOf(interval as OpUnitType).toDate(),
    endDate: date.endOf(interval as OpUnitType).toDate(),
    key: 'selection',
  };
};

export function convertUsShortFormatToIso(date: string) {
  return dayjs(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
}

export function convertIsoToDateUsShortFormat(date: string) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) return date;
  return dayjs(date).format('MM/DD/YY');
}

export function formatLabelPerInterval(label: string, interval: string) {
  if (interval === 'month') {
    return dayjs.utc(label).format('MMM');
  }

  return dayjs.utc(label).startOf('week').format('M-D');
}

export function getDateBySelectedInterval(
  interval: string,
  selectedDate: Date,
) {
  return {
    selection: getSelectionContents(interval, selectedDate),
    compare: getComparisonContents(interval, selectedDate),
  };
}

export const getComparisonContents = (interval: string, selectedDate: Date) => {
  const result = getSelectionContents(
    interval,
    dayjs(selectedDate)
      .subtract(1, interval as ManipulateType)
      .toDate(),
  );

  result.key = 'compare';

  return result;
};

export function interval(startDate: string, endDate: string) {
  const diffDays = getDifferenceInDays(startDate, endDate);
  let interval;

  if (diffDays <= 3) {
    interval = 'hour';
  }
  if (diffDays > 3 && diffDays <= 30) {
    interval = 'day';
  }
  if (diffDays > 30 && diffDays <= 90) {
    interval = 'week';
  }
  if (diffDays > 90 && diffDays <= 180) {
    interval = 'month';
  }
  if (diffDays > 180) {
    interval = 'year';
  }

  return interval;
}
